.root {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  font-size: .875rem;
}

.divider {
  display: flex;
  align-items: center;
  margin: 0 .25rem;
}

.segment {
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  padding: .5rem;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.segment:last-child {
  cursor: default;
}
