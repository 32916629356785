.switch {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.25rem;
}

.switch input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.switch input:disabled:checked ~ label::before {
  background-color: #e9ecef;
}

.switch input:checked ~ label::after {
  background-color: #fff;
  transform: translateX(.75rem);
}

.switch input:checked ~ label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.switch label {
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.switch label::before {
  content: '';
  position: absolute;
  display: block;
  height: 1rem;
  background-color: #fff;
  border: #adb5bd solid 1px;
  top: 0.25rem;
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.switch label::after {
  content: '';
  position: absolute;
  display: block;
  height: 1rem;
  border: #adb5bd solid 1px;
  top: calc(.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  pointer-events: all;
  border-radius: 0.5rem;
  background-color: #fff;
  background: no-repeat 50%/50% 50%;
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
}
