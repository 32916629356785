.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

.button {
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex: 1 1 3rem;
  justify-content: center;
  border-radius: .25rem;
  background-color: var(--palette-background-paper);
  box-shadow: var(--palette-shadow-2);
  text-decoration: none;
  margin: .5rem .25rem;
  padding: .5rem;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

.button:hover {
  background-color: var(--palette-action-hover);
}
