.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.items {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
