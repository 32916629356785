@value moduleHeight: 4rem;

.root {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  outline: 0;
  transition: opacity 0.15s linear;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 9;
  opacity: 0.5;
}

.dialog {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  pointer-events: none;
  transition: transform 0.3s ease-out;
  transform: none;
  margin: auto;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  outline: 0;
  overflow: hidden;
  max-height: 486px;
  max-width: 800px;
}

.head {
  display: flex;
  flex-direction: row;
  height: moduleHeight;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
}

.title {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 600;
}

.dateRange {
  width: 100%;
}

.report {
  display: block;
  padding: 1rem;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
}
