.root {
  display: flex;
  flex-direction: column;
  background: var(--palette-background-paper);
  box-shadow: var(--palette-shadow-3);
  position: relative;
  height: 100%;
  right: 0;
  z-index: 100;
}

.head {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 .5rem;
  min-height: 1rem;
}

.body {
  flex: 1 1 auto;
  overflow: auto;
}

.menuItem {
  display: flex;
  flex-direction: row;
  padding: .5rem 1rem;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.menuItem:hover {
  background-color: var(--palette-action-hover);
}

.menuItem.menuItemActive,
.menuItem.menuItemActive:hover {
  background-color: var(--palette-action-selected);
}

.menuIcon {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  opacity: .75;
}

.menuBtn {
  composes: buttonTransparent from '../../../common/styles/button.module.css';
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 0.85;
}

.menuBtn:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.08);
}
