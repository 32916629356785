*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-size: 14px;
  height: 100%;
}

body {
  position: fixed;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  touch-action: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

dt {
  font-weight: 500;
}

#root {
  height: 100%;
}

.btn-icon {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  line-height: 0;
}

.btn-icon:hover {
  background-color: var(--palette-action-hover);
}

.btn-text {
  color: inherit;
  background-color: transparent;
  border-color: transparent;
}

.btn-text:hover {
  background-color: var(--palette-action-hover);;
}
