.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #4c4c4c;
  width: 100%;
  min-height: 52px;
  padding: 0 0.5rem 0 1rem;
  color: white;
  font-size: .875rem;
}

.left {
  flex: 1 1 auto;
}

.right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menuBtn {
  composes: buttonTransparent from '../../../common/styles/button.module.css';
  display: flex;
  align-items: center;
  opacity: .85;
  font-size: 0.85rem;
}
.menuBtn:hover {
  opacity: 1;
}
