.root {
  position: relative;
  display: flex;
  align-items: center;
}

.expanded {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.cancel {
  composes: buttonTransparent from '../../../../../common/styles/button.module.css';
  position: absolute;
  right: 4px;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: .7;
}
.cancel:hover {
  opacity: 1;
}
