$palette-primary-light: #63a4ff;
$palette-primary-main: #1976d2;
$palette-primary-dark: #004ba0;
$palette-primary-contrastText: #ffffff;

$palette-secondary-light: #ffa040;
$palette-secondary-main: #ff6f00;
$palette-secondary-dark: #c43e00;
$palette-secondary-contrastText: #000000;

$palette-error-light: #e57373;
$palette-error-main: #f44336;
$palette-error-dark: #d32f2f;
$palette-warning-light: #ffb74d;
$palette-warning-main: #ff9800;
$palette-warning-dark: #f57c00;
$palette-info-light: #64b5f6;
$palette-info-main: #2196f3;
$palette-info-dark: #1976d2;
$palette-success-light: #81c784;
$palette-success-main: #4caf50;
$palette-success-dark: #388e3c;

$palette-grey-50: #fafafa;
$palette-grey-100: #f5f5f5;
$palette-grey-200: #eeeeee;
$palette-grey-300: #e0e0e0;
$palette-grey-400: #bdbdbd;
$palette-grey-500: #9e9e9e;
$palette-grey-600: #757575;
$palette-grey-700: #616161;
$palette-grey-800: #424242;
$palette-grey-900: #212121;
$palette-grey-A100: #d5d5d5;
$palette-grey-A200: #aaaaaa;
$palette-grey-A400: #303030;
$palette-grey-A700: #616161;

$palette-text-primary: rgba(0, 0, 0, 0.87);
$palette-text-secondary: rgba(0, 0, 0, 0.54);
$palette-text-disabled: rgba(0, 0, 0, 0.38);

$palette-action-active: rgba(0, 0, 0, 0.54);
$palette-action-hover: rgba(0, 0, 0, 0.04);
$palette-action-selected: rgba(0, 0, 0, 0.08);
$palette-action-disabled: rgba(0, 0, 0, 0.26);
$palette-action-disabledBackground: rgba(0, 0, 0, 0.12);
$palette-action-focus: rgba(0, 0, 0, 0.12);

$palette-background-default: #fafafa;
$palette-background-paper: #ffffff;

$palette-divider: rgba(0, 0, 0, 0.12);

$palette-shadow-0: none;
$palette-shadow-1: 0 2px 1px -1px rgba(0,0,0,0.2),0 1px 1px 0 rgba(0,0,0,0.14),0 1px 3px 0 rgba(0,0,0,0.12);
$palette-shadow-2: 0 3px 1px -2px rgba(0,0,0,0.2),0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12);
$palette-shadow-3: 0 3px 3px -2px rgba(0,0,0,0.2),0 3px 4px 0 rgba(0,0,0,0.14),0 1px 8px 0 rgba(0,0,0,0.12);
$palette-shadow-4: 0 2px 4px -1px rgba(0,0,0,0.2),0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10 0 rgba(0,0,0,0.12);
$palette-shadow-5: 0 3px 5px -1px rgba(0,0,0,0.2),0 5px 8px 0 rgba(0,0,0,0.14),0 1px 14px 0 rgba(0,0,0,0.12);
$palette-shadow-6: 0 3px 5px -1px rgba(0,0,0,0.2),0 6px 10 0 rgba(0,0,0,0.14),0 1px 18px 0 rgba(0,0,0,0.12);
$palette-shadow-7: 0 4px 5px -2px rgba(0,0,0,0.2),0 7px 10 1px rgba(0,0,0,0.14),0 2px 16px 1px rgba(0,0,0,0.12);
$palette-shadow-8: 0 5px 5px -3px rgba(0,0,0,0.2),0 8px 10 1px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12);
$palette-shadow-9: 0 5px 6px -3px rgba(0,0,0,0.2),0 9px 12px 1px rgba(0,0,0,0.14),0 3px 16px 2px rgba(0,0,0,0.12);
$palette-shadow-10: 0 6px 6px -3px rgba(0,0,0,0.2),0 10 14px 1px rgba(0,0,0,0.14),0 4px 18px 3px rgba(0,0,0,0.12);
$palette-shadow-11: 0 6px 7px -4px rgba(0,0,0,0.2),0 11px 15px 1px rgba(0,0,0,0.14),0 4px 20 3px rgba(0,0,0,0.12);
$palette-shadow-12: 0 7px 8px -4px rgba(0,0,0,0.2),0 12px 17px 2px rgba(0,0,0,0.14),0 5px 22px 4px rgba(0,0,0,0.12);
$palette-shadow-13: 0 7px 8px -4px rgba(0,0,0,0.2),0 13px 19px 2px rgba(0,0,0,0.14),0 5px 24px 4px rgba(0,0,0,0.12);
$palette-shadow-14: 0 7px 9px -4px rgba(0,0,0,0.2),0 14px 21px 2px rgba(0,0,0,0.14),0 5px 26px 4px rgba(0,0,0,0.12);
$palette-shadow-15: 0 8px 9px -5px rgba(0,0,0,0.2),0 15px 22px 2px rgba(0,0,0,0.14),0 6px 28px 5px rgba(0,0,0,0.12);
$palette-shadow-16: 0 8px 10 -5px rgba(0,0,0,0.2),0 16px 24px 2px rgba(0,0,0,0.14),0 6px 30 5px rgba(0,0,0,0.12);
$palette-shadow-17: 0 8px 11px -5px rgba(0,0,0,0.2),0 17px 26px 2px rgba(0,0,0,0.14),0 6px 32px 5px rgba(0,0,0,0.12);
$palette-shadow-18: 0 9px 11px -5px rgba(0,0,0,0.2),0 18px 28px 2px rgba(0,0,0,0.14),0 7px 34px 6px rgba(0,0,0,0.12);
$palette-shadow-19: 0 9px 12px -6px rgba(0,0,0,0.2),0 19px 29px 2px rgba(0,0,0,0.14),0 7px 36px 6px rgba(0,0,0,0.12);
$palette-shadow-20: 0 10 13px -6px rgba(0,0,0,0.2),0 20 31px 3px rgba(0,0,0,0.14),0 8px 38px 7px rgba(0,0,0,0.12);
$palette-shadow-21: 0 10 13px -6px rgba(0,0,0,0.2),0 21px 33px 3px rgba(0,0,0,0.14),0 8px 40 7px rgba(0,0,0,0.12);
$palette-shadow-22: 0 10 14px -6px rgba(0,0,0,0.2),0 22px 35px 3px rgba(0,0,0,0.14),0 8px 42px 7px rgba(0,0,0,0.12);
$palette-shadow-23: 0 11px 14px -7px rgba(0,0,0,0.2),0 23px 36px 3px rgba(0,0,0,0.14),0 9px 44px 8px rgba(0,0,0,0.12);
$palette-shadow-24: 0 11px 15px -7px rgba(0,0,0,0.2),0 24px 38px 3px rgba(0,0,0,0.14),0 9px 46px 8px rgba(0,0,0,0.12);

:root {
  --palette-primary-light: #{$palette-primary-light};
  --palette-primary-main: #{$palette-primary-main};
  --palette-primary-dark: #{$palette-primary-dark};
  --palette-primary-contrastText:  #{$palette-primary-contrastText};

  --palette-secondary-light: #{$palette-secondary-light};
  --palette-secondary-main: #{$palette-secondary-main};
  --palette-secondary-dark: #{$palette-secondary-dark};
  --palette-secondary-contrastText: #{$palette-secondary-contrastText};

  --palette-error-light: #{$palette-error-light};
  --palette-error-main: #{$palette-error-main};
  --palette-error-dark: #{$palette-error-dark};
  --palette-warning-light: #{$palette-warning-light};
  --palette-warning-main: #{$palette-warning-main};
  --palette-warning-dark: #{$palette-warning-dark};
  --palette-info-light: #{$palette-info-light};
  --palette-info-main: #{$palette-info-main};
  --palette-info-dark: #{$palette-info-dark};
  --palette-success-light: #{$palette-success-light};
  --palette-success-main: #{$palette-success-main};
  --palette-success-dark: #{$palette-success-dark};

  --palette-grey-50: #{$palette-grey-50};
  --palette-grey-100: #{$palette-grey-100};
  --palette-grey-200: #{$palette-grey-200};
  --palette-grey-300: #{$palette-grey-300};
  --palette-grey-400: #{$palette-grey-400};
  --palette-grey-500: #{$palette-grey-500};
  --palette-grey-600: #{$palette-grey-600};
  --palette-grey-700: #{$palette-grey-700};
  --palette-grey-800: #{$palette-grey-800};
  --palette-grey-900: #{$palette-grey-900};
  --palette-grey-A100: #{$palette-grey-A100};
  --palette-grey-A200: #{$palette-grey-A200};
  --palette-grey-A400: #{$palette-grey-A400};
  --palette-grey-A700: #{$palette-grey-A700};

  --palette-text-primary: #{$palette-text-primary};
  --palette-text-secondary: #{$palette-text-secondary};
  --palette-text-disabled: #{$palette-text-disabled};

  --palette-action-active: #{$palette-action-active};
  --palette-action-hover: #{$palette-action-hover};
  --palette-action-selected: #{$palette-action-selected};
  --palette-action-disabled: #{$palette-action-disabled};
  --palette-action-disabledBackground: #{$palette-action-disabledBackground};
  --palette-action-focus: #{$palette-action-focus};

  --palette-background-default: #{$palette-background-default};
  --palette-background-paper: #{$palette-background-paper};

  --palette-divider: #{$palette-divider};

  --palette-shadow-0: #{$palette-shadow-0};
  --palette-shadow-1: #{$palette-shadow-1};
  --palette-shadow-2: #{$palette-shadow-2};
  --palette-shadow-3: #{$palette-shadow-3};
  --palette-shadow-4: #{$palette-shadow-4};
  --palette-shadow-5: #{$palette-shadow-5};
  --palette-shadow-6: #{$palette-shadow-6};
  --palette-shadow-7: #{$palette-shadow-7};
  --palette-shadow-8: #{$palette-shadow-8};
  --palette-shadow-9: #{$palette-shadow-9};
  --palette-shadow-10: #{$palette-shadow-10};
  --palette-shadow-11: #{$palette-shadow-11};
  --palette-shadow-12: #{$palette-shadow-12};
  --palette-shadow-13: #{$palette-shadow-13};
  --palette-shadow-14: #{$palette-shadow-14};
  --palette-shadow-15: #{$palette-shadow-15};
  --palette-shadow-16: #{$palette-shadow-16};
  --palette-shadow-17: #{$palette-shadow-17};
  --palette-shadow-18: #{$palette-shadow-18};
  --palette-shadow-19: #{$palette-shadow-19};
  --palette-shadow-20: #{$palette-shadow-20};
  --palette-shadow-21: #{$palette-shadow-21};
  --palette-shadow-22: #{$palette-shadow-22};
  --palette-shadow-23: #{$palette-shadow-23};
  --palette-shadow-24: #{$palette-shadow-24};
}
