.button {
  display: inline-block;
  color: #212529;
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: default;
  user-select: none;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-family: inherit;
  overflow: visible;
  text-transform: none;
  text-decoration: none;
  -webkit-appearance: button;
  box-sizing: border-box;
}

.button:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216,217,219,.5);
  outline: none;
}

.button:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  text-decoration: none;
}

.button:not(:disabled),
.button:not:global(.disabled) {
  cursor: pointer;
}

.button:disabled,
.button:global(.disabled) {
  opacity: .65;
}

.button:global(.disabled) {
  pointer-events: none;
}


.buttonTransparent {
  display: inline-block;
  color: inherit;
  background-color: transparent;
  border: 1px solid transparent;
  font-weight: inherit;
  text-align: center;
  vertical-align: middle;
  cursor: default;
  user-select: none;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-family: inherit;
  overflow: visible;
  text-transform: none;
  text-decoration: none;
  -webkit-appearance: button;
  box-sizing: border-box;
  outline: none;
}

.buttonTransparent:hover {
  text-decoration: none;
}

.buttonTransparent:focus {
  outline: none;
}

.buttonTransparent:not(:disabled) {
  cursor: pointer;
}

.buttonTransparent:disabled {
  opacity: .65;
}


.buttonPrimary {
  composes: button;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.buttonPrimary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.buttonPrimary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.buttonPrimary:disabled,
.buttonPrimary:global(.disabled) {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}


.buttonLink {
  composes: button;
  font-weight: 400;
  color: #007bff;
  background: transparent;
  border-color: transparent;
  text-decoration: none;
}

.buttonLink:focus {
  box-shadow: none;
}

.buttonLink:hover {
  color: #0056b3;
}


.input {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin: 0;
  font-family: inherit;
  overflow: visible;
}

.input:focus,
.input:global(.focus) {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}
