.root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.fontSizeDefault {
  font-size: 1rem;
}

.fontSizeSmall {
  font-size: .75rem;
}

.fontSizeLarge {
  font-size: 2.25rem;
}

.colorPrimary {
  color: #007bff;
}
