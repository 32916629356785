.root {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: .875rem;
  min-width: 368px;
  box-shadow: var(--palette-shadow-3);
}
@media screen and (min-width: 1366px) {
  .root { min-width: 394px; }
}
@media screen and (min-width: 1920px) {
  .root { min-width: 474px; }
}

.actions {
  position: relative;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #545454;
  color: white;
  font-size: .875rem;
}
.actions:after {
  content: none;
  position: absolute;
  top: 12px;
  right: 0;
  height: 30px;
  width: 1px;
  background-color: gray;
}

.head {
  display: none;
}

.head dl {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
  overflow: visible;
}
.head dl dt {
  flex: 0 0 30%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.head dl dd {
  flex:0 0 70%;
  margin-left: auto;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.body {
  flex: 1 1 auto;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.foot {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.listing {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.row {
  cursor: pointer;
}

.cell {
  padding: 0.75rem 0;
  border-bottom: 1px solid rgba(0,0,0,.08);
  vertical-align: middle;
  user-select: none;
}

.cellName {
  text-align: left;
  padding-left: 1rem;
  font-weight: 600;
}

.cellPrice {
  text-align: right;
  padding: 0 1rem;
  width: 20%;
  white-space: nowrap;
}

.receiptsBtn {
  composes: buttonTransparent from '../../../common/styles/button.module.css';
  font-size: 1rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  opacity: .85;
}
.receiptsBtn:hover {
  opacity: 1;
}

.receiptsIcon {
  opacity: .5;
}

.ghostly {
  opacity: .8;
  font-weight: 400;
}
