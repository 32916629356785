.title {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 600;
}

.control {
  display: flex;
  margin-bottom: 1rem;
}

.controlGroup {
  flex: 1 1 auto;
}

.picker {
  padding: .5rem .25rem;
  border: 1px solid #80bdff;
  border-top: transparent;
  border-radius: 0 0 .25rem .25rem;
}
