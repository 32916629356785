.root {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.nav {
  width: 250px;
}

.content {
  flex: 1 1 auto;
  overflow: auto;
  background-color: var(--palette-background-default);
}
